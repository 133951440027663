var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"text-start px-6 py-8",attrs:{"rounded":"xl","width":_vm.$vuetify.breakpoint.mdAndUp ? '786px' : _vm.$vuetify.breakpoint.smAndUp ? '586px' : ''}},[_c('div',{staticClass:"d-flex justify-space-between mb-6 align-center"},[_c('div',{staticClass:"text-h6 text-medium"},[_vm._v("My MultiClaimer")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.addPoolState = !_vm.addPoolState}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":20}},[_vm._v(" mdi-plus ")]),_vm._v(" New pool ")],1)],1),_c('div',{class:{
        'd-flex justify-space-between mb-4 align-center': _vm.$vuetify.breakpoint.smAndUp,
        'd-flex flex-column': _vm.$vuetify.breakpoint.xs,
      }},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","outlined":"","rounded":"","color":_vm.completed ? 'primary' : ''},on:{"click":_vm.toggleCompletedFilter}},[_vm._v(" Completed ")]),_c('v-btn',{attrs:{"small":"","outlined":"","rounded":"","color":_vm.remaining ? 'primary' : ''},on:{"click":_vm.toggleRemainingFilter}},[_vm._v(" Remaining ")])],1),_c('div',{class:_vm.$vuetify.breakpoint.xs ? 'my-4' : ''},[_vm._v(" Date Created "),_c('v-icon',{attrs:{"color":_vm.isIncreaseCreatedDate ? 'primary' : ''},on:{"click":_vm.toggleOrderByCreatedDate}},[_vm._v("mdi-swap-vertical")])],1)]),(!_vm.validClaims || _vm.validClaims.length <= 0)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-sheet',{staticClass:"align-self-center",attrs:{"color":"primary secondary pa-6","rounded":"lg","width":_vm.$vuetify.breakpoint.mdAndDown ? '' : '705px'}},[_c('div',{staticClass:"d-flex flex-column align-center text-center mb-16 mt-6"},[_c('v-img',{attrs:{"src":require("@/assets/icons/search-document.svg"),"max-width":"110"}}),_c('div',{staticClass:"my-3"},[_vm._v("You have no pool.")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.addPoolState = !_vm.addPoolState}}},[_vm._v(" Creat new pool ?")])],1)])],1):_vm._l((_vm.validClaims),function(item,index){return _c('v-sheet',{key:index,attrs:{"color":"primary secondary pa-6 mb-6","rounded":"lg"}},[_c('div',{class:{
          'd-flex justify-space-between': _vm.$vuetify.breakpoint.smAndUp,
          'd-flex flex-column': _vm.$vuetify.breakpoint.xs,
        }},[_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.xs ? 'mb-2' : ''},[_c('v-img',{attrs:{"src":require("@/assets/icons/claim-icon.svg"),"max-width":"24px","contain":""}}),_c('div',{staticClass:"ml-2 text-medium font-weight-medium"},[_vm._v(_vm._s(item.name))])],1),_c('div',[(item.claimedNumber === item.winnerNumber && _vm.afterActiveDate(item.activeDate))?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success ligten-2 "}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Completed ")],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-minus")]),_vm._v(" Remaining ("+_vm._s(!_vm.afterActiveDate(item.activeDate) ? 0 : item.claimedNumber)+"/"+_vm._s(item.winnerNumber)+") ")],1)])]),_c('v-progress-linear',{staticClass:"my-6",attrs:{"height":"2","background-color":"rgba(255, 255, 255, 0.06)","color":"primary","value":!_vm.afterActiveDate(item.activeDate) ? 0 : (item.claimedNumber * 100) / (item.winnerNumber || 1)}}),_c('div',{class:{
          'd-flex justify-space-between': _vm.$vuetify.breakpoint.smAndUp,
          'd-flex flex-column': _vm.$vuetify.breakpoint.xs,
        }},[_c('div',{class:{
            'd-flex justify-space-between mb-2': _vm.$vuetify.breakpoint.xs,
            'd-flex flex-column': _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('div',[_vm._v("Date created")]),_c('div',[_vm._v(_vm._s(_vm._f("ddmmyyyy")(item.createdDate)))])]),_c('div',{class:{
            'd-flex justify-space-between mb-2': _vm.$vuetify.breakpoint.xs,
            'd-flex flex-column': _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('div',[_vm._v("Total amount")]),_c('div',[_vm._v(_vm._s(_vm._f("formatNumber")(item.amount,8, 1))+" "+_vm._s(item.token && item.token.symbol))])]),_c('div',{class:{
            'd-flex justify-space-between': _vm.$vuetify.breakpoint.xs,
            'd-flex flex-column': _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('div',[_vm._v("Address")]),_c('div',[_vm._v(_vm._s(item.winnerNumber)+" Wallet")])]),_c('v-btn',{staticClass:"px-0",attrs:{"color":"primary","text":"","to":("owner/" + (item.id))}},[_vm._v(" View detail ")])],1)],1)}),_c('ClaimAddDialog',{attrs:{"state":_vm.addPoolState},on:{"addClaimDone":function($event){return _vm.addClaimDone()}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }