<template>
  <div>
    <v-sheet
      rounded="xl"
      class="text-start px-6 py-8"
      :width="$vuetify.breakpoint.mdAndUp ? '786px' : $vuetify.breakpoint.smAndUp ? '586px' : ''"
    >
      <div class="d-flex justify-space-between mb-6 align-center">
        <div class="text-h6 text-medium">My MultiClaimer</div>
        <v-btn outlined rounded color="primary" @click="addPoolState = !addPoolState">
          <v-icon :size="20" class="mr-2"> mdi-plus </v-icon>
          New pool
        </v-btn>
      </div>
      <div
        :class="{
          'd-flex justify-space-between mb-4 align-center': $vuetify.breakpoint.smAndUp,
          'd-flex flex-column': $vuetify.breakpoint.xs,
        }"
      >
        <div class="d-flex">
          <v-btn small outlined rounded :color="completed ? 'primary' : ''" class="mr-2" @click="toggleCompletedFilter">
            Completed
          </v-btn>
          <v-btn small outlined rounded :color="remaining ? 'primary' : ''" @click="toggleRemainingFilter">
            Remaining
          </v-btn>
        </div>
        <div :class="$vuetify.breakpoint.xs ? 'my-4' : ''">
          Date Created
          <v-icon :color="isIncreaseCreatedDate ? 'primary' : ''" @click="toggleOrderByCreatedDate"
            >mdi-swap-vertical</v-icon
          >
        </div>
      </div>
      <div class="d-flex justify-center" v-if="!validClaims || validClaims.length <= 0">
        <v-sheet
          color="primary secondary pa-6"
          rounded="lg"
          class="align-self-center"
          :width="$vuetify.breakpoint.mdAndDown ? '' : '705px'"
        >
          <div class="d-flex flex-column align-center text-center mb-16 mt-6">
            <v-img :src="require(`@/assets/icons/search-document.svg`)" max-width="110"></v-img>
            <div class="my-3">You have no pool.</div>
            <v-btn text color="primary" @click="addPoolState = !addPoolState"> Creat new pool ?</v-btn>
          </div>
        </v-sheet>
      </div>
      <v-sheet
        color="primary secondary pa-6 mb-6"
        rounded="lg"
        v-else
        v-for="(item, index) in validClaims"
        :key="index"
      >
        <div
          :class="{
            'd-flex justify-space-between': $vuetify.breakpoint.smAndUp,
            'd-flex flex-column': $vuetify.breakpoint.xs,
          }"
        >
          <div class="d-flex" :class="$vuetify.breakpoint.xs ? 'mb-2' : ''">
            <v-img :src="require(`@/assets/icons/claim-icon.svg`)" max-width="24px" contain />
            <div class="ml-2 text-medium font-weight-medium">{{ item.name }}</div>
          </div>
          <div>
            <div
              v-if="item.claimedNumber === item.winnerNumber && afterActiveDate(item.activeDate)"
              class="d-flex align-center"
            >
              <v-icon class="mr-2" color="success ligten-2 ">mdi-check-circle-outline</v-icon>
              Completed
            </div>
            <div v-else class="d-flex align-center">
              <v-icon class="mr-4">mdi-minus</v-icon>
              Remaining ({{ !afterActiveDate(item.activeDate) ? 0 : item.claimedNumber }}/{{ item.winnerNumber }})
            </div>
          </div>
        </div>
        <v-progress-linear
          height="2"
          background-color="rgba(255, 255, 255, 0.06)"
          color="primary"
          class="my-6"
          :value="!afterActiveDate(item.activeDate) ? 0 : (item.claimedNumber * 100) / (item.winnerNumber || 1)"
        />
        <div
          :class="{
            'd-flex justify-space-between': $vuetify.breakpoint.smAndUp,
            'd-flex flex-column': $vuetify.breakpoint.xs,
          }"
        >
          <div
            :class="{
              'd-flex justify-space-between mb-2': $vuetify.breakpoint.xs,
              'd-flex flex-column': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div>Date created</div>
            <div>{{ item.createdDate | ddmmyyyy }}</div>
          </div>
          <div
            :class="{
              'd-flex justify-space-between mb-2': $vuetify.breakpoint.xs,
              'd-flex flex-column': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div>Total amount</div>
            <div>{{ item.amount | formatNumber(8, 1) }} {{ item.token && item.token.symbol }}</div>
          </div>

          <div
            :class="{
              'd-flex justify-space-between': $vuetify.breakpoint.xs,
              'd-flex flex-column': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div>Address</div>
            <div>{{ item.winnerNumber }} Wallet</div>
          </div>
          <v-btn color="primary" text class="px-0" :to="`owner/${item.id}`"> View detail </v-btn>
        </div>
      </v-sheet>
      <ClaimAddDialog :state="addPoolState" @addClaimDone="addClaimDone()" />
    </v-sheet>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  props: {
    url: String,
  },
  components: {
    ClaimAddDialog: () => import('@/components/dialogs/claimer/ClaimAddDialog.vue'),
  },
  data() {
    return {
      addPoolState: false,
    }
  },
  computed: {
    ...mapGetters('claimPools', ['validClaims']),
    ...mapState('claimPools', ['remaining', 'completed', 'isIncreaseCreatedDate']),
    ...mapState('auth', ['multiClaimHandler']),
  },
  methods: {
    ...mapActions('claimPools', [
      'fetchData',
      'toggleRemainingFilter',
      'toggleCompletedFilter',
      'toggleOrderByCreatedDate',
    ]),
    async addClaimDone() {
      await this.fetchData(this.multiClaimHandler)
    },
    afterActiveDate(activeDate) {
      if (!activeDate) return false
      return moment(activeDate * 1000).isBefore(moment())
    },
  },
  async created() {
    await this.fetchData(this.multiClaimHandler)
  },
}
</script>